<template>
  <div class="wrapper">
    <div class="wrapper__container">
      <jublia-header />
      <div class="container">
        <jublia-nav-step :trad="'treatment.evolution.title'" />
        <jublia-p class="w-full mt-6 mb-10 text"
          >{{ $t('treatment.evolution.text') }}
        </jublia-p>
        <div
          @click="triggerClick"
          class="bg-jublia-bluest text-jublia-white mt-4 h-64 rounded-lg flex justify-center items-center"
        >
          <jublia-loader v-if="isLoading" />
          <div v-else class="flex flex-col justify-around items-center">
            <img
              src="@/assets/icons/Asset131.png"
              alt="picture icon"
              height="45"
              width="45"
            />
            <jublia-p class="mt-2">{{ $t('common.accessCamera') }}</jublia-p>
            <input
              id="files"
              @change="uploadImage"
              accept=".jpg, .png|image/*"
              style="visibility:hidden;"
              type="file"
              class="absolute none w-full"
              capture
            />
          </div>
        </div>
        <div
          @click="skip()"
          v-html="$t('treatment.treatmentProgress.skipPicture')"
          class="block text-base underline cursor-pointer text-center mt-5 text text2"
        />
        <jublia-breadcrumb :currentStep="4" :totalStep="4" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import JubliaP from '@/components/text/jublia-p'
import JubliaBreadcrumb from '@/components/breadcrumb/jublia-breadcrumb'
import JubliaLoader from '@/components/loader/jublia-loader'
import JubliaHeader from '@/components/header/jublia-header'
import JubliaNavStep from '@/components/nav-step/jublia-nav-step'
import axios from 'axios';
export default {
  name: 'StepPhotos',
  components: {
    'jublia-nav-step':JubliaNavStep,
    'jublia-header':JubliaHeader,
    'jublia-p':JubliaP,
    'jublia-breadcrumb': JubliaBreadcrumb,
   'jublia-loader': JubliaLoader
  },
  data: () => ({
    isLoading: false,
    errors: [],
    timeError: null
  }),
  asyncData({ params }) {
    return { params }
  },
  methods: {
    triggerClick(e) {
      if (this.isLoading) return
      this.$el.querySelector('#files').click()
    },
    skip() {
      //console.log()
      const user = this.$route.params.userId
      if (!user) return
      this.$router.push(`/${user}/upload`)
    },
    async uploadImage(evt) {
      this.isLoading = true
      const user = this.$route.params.userId
      if (!user) return
      const fd = new FormData()
      fd.append('image', evt.target.files[0], fd.name)
      try {
        await axios.post(`/user/${user}/images`, fd)
        this.isLoading = false
        this.$router.push(`/${user}/upload`)
      } catch (e) {
        this.isLoading = false
      }
    },
    ...mapMutations('menu',['toggleMenu'])
  }
}
</script>
<style lang="postcss" scoped>
.text2 {
  text-align:center !important;
}

</style>